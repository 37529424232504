import { cn } from '@virginexperiencedays/tailwind';
import { useAspectRatioSupported } from '@virginexperiencedays/hooks';

import { Image } from '../../layout/Image';
import { DisplayHeading, DisplayHeadingProps } from '../../typography/DisplayHeading';
import { LinkWrap } from '../../navigation/LinkWrap';
import { IconLocation } from '../../icons/IconLocation';
import { IconFavourite } from '../../icons/IconFavourite';
import { roundDistance } from '../../utils/productLocation';
import { MediaCard } from '../Media';
import { RatingsAndReviews } from '../CarouselProduct/ProductRatingAndReviews';

import {
  mapAspectRatio,
  mapAspectRatioPolyfill,
  ResponsiveAspectRatio,
} from '../../utils/mapAspectRatio';

import { getCardBadgeText } from '../../utils/getCardBadgeText';
import { formatLocations, formatNoOfLocations } from '../../utils/productLocation';
import { getProductCardPrices } from '../../utils/productPricing';

import type { ProductPromo } from '@virginexperiencedays/promos';
import type { MouseEvent } from 'react';

export type ProductCardProps = {
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  /** Title of the category */
  title: string;
  /** SEO Override */
  titleAs?: DisplayHeadingProps['as'];
  /** Location names */
  locations?: {
    name: string;
  }[];
  /* Can accept number instead */
  noOfLocations?: number;
  /** Nearest location displayed below title */
  nearestLocation?: {
    /** Nearest location name */
    name: string;
    /** Nearest location distance */
    distance: number;
  };
  /** Average rating displayed below title */
  averageRating?: number;
  /** Total reviews  displayed below title */
  totalReviews?: number;
  /** shortDescription */
  shortDescription?: string;
  /** Image src to be displayed in the card */
  src: string;
  /** alt of the image */
  alt?: string;
  /** Flag whether to prefetch the link (NextJS Link only) */
  prefetch?: boolean;
  /** Whether to force external link behavior for href */
  isHrefExternal?: boolean;
  /** Regular Price */
  rrp?: number;
  /** Discounted price */
  displayPrice: number;
  /** Percent off displayed as badge on media card image */
  percentOff?: number;
  /** Displays new label in top right corner */
  isNew?: boolean;
  /** Displays label if on sale */
  isOnSale?: boolean;
  /** Displays label if exclusive */
  isExclusive?: boolean;
  /** Later on there will be some logic to fill the heart when the given product is in favourite list */
  isFavourite?: boolean;
  /** Define wether to use media card (different badge positions) or default one (only favourite badge and sale in bottom left corner) */
  withMediaCard?: boolean;
  /** Aspect Ratio of the card (responsive props) */
  aspectRatio?: ResponsiveAspectRatio;
  /** Wether to show or not the favourite label */
  showFavouriteLabel?: boolean;
  /** Whether to show non-zero discounts when NOT using media card */
  showDiscount?: boolean;
  /** Black-Friday-specific banner */
  showBlackFridayBanner?: boolean;
  isMinimal?: boolean;
  /** Product promotional code */
  promocode?: string;
  /** Product sku */
  sku: string;
  /** product slug name */
  slug: string;
  /** isCollectionProduct determine if the product is for collection page */
  isCollectionProduct?: boolean;
  /** Tracking, etc */
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  priority?: boolean;
  productPromo: ProductPromo | null;
};

export const ProductCard = ({
  className,
  titleClassName,
  contentClassName,
  title,
  titleAs,
  locations,
  noOfLocations,
  nearestLocation,
  averageRating,
  totalReviews,
  shortDescription,
  src,
  alt,
  prefetch,
  isHrefExternal,
  rrp,
  percentOff,
  displayPrice,
  promocode,
  sku,
  isExclusive = false,
  isNew = false,
  isOnSale = false,
  withMediaCard = false,
  aspectRatio = '1/1',
  showFavouriteLabel = false,
  showDiscount = false,
  isMinimal = false,
  isCollectionProduct = false,
  slug,
  priority,
  onClick = () => {},
}: ProductCardProps) => {
  const { isSupported } = useAspectRatioSupported();

  const contentList = shortDescription?.split('•\t');

  const { currentPrice, pastPrice, roundedPercentOff } = getProductCardPrices({
    displayPrice,
    rrp,
    percentOff,
  });

  const hasRating = averageRating > 0 && totalReviews > 0 && !isCollectionProduct;
  const productUrl = isCollectionProduct ? `/collection/${slug}` : `/product/${slug}`;
  const normalizedLocations = formatLocations(locations) ?? formatNoOfLocations(noOfLocations);
  const badgeText = getCardBadgeText({ isNew, isExclusive });

  return (
    <LinkWrap
      className={cn(
        'group flex gap-2',
        isMinimal ? 'flex-row' : 'border-border-neutral flex-col rounded border shadow-sm',
        'md:flex-col md:rounded-none md:border-0 md:shadow-none',
        className
      )}
      href={productUrl}
      prefetch={prefetch}
      isExternal={isHrefExternal}
      onClick={(e) => onClick(e)}
      dataTestId="product-card"
      dataProductId={promocode ? `${sku} ${promocode}` : sku}
    >
      {withMediaCard ? (
        <MediaCard
          src={src}
          alt={alt ?? title}
          savingBadge={roundedPercentOff}
          aspectRatio={aspectRatio}
          newBadge={isNew}
          favourite
          rounded
          priority={priority}
        />
      ) : (
        <div
          className={cn(
            'relative min-w-[120px] overflow-hidden rounded will-change-transform',
            isMinimal && 'md:self-[unset] self-start md:min-w-full',
            isSupported && mapAspectRatio(aspectRatio),
            !isSupported && mapAspectRatioPolyfill(aspectRatio)
          )}
        >
          <Image
            fill
            className={cn(
              'bg-background-neutral-faded image:object-cover transition-all duration-500 ease-in-out will-change-transform',
              'group-hover:scale-105',
              isSupported && mapAspectRatio(aspectRatio),
              !isSupported && mapAspectRatioPolyfill(aspectRatio)
            )}
            dataTestId="product-card-image"
            src={src}
            alt={alt ?? title}
            priority={priority}
          />
          <>
            {/* (Top Left) Exclusive / New badge */}
            {badgeText && (
              <span
                className={cn(styles.badgeText, styles.badgeTextDefault)}
                data-testid="product-card-new-or-exclusive-badge"
              >
                {badgeText}
              </span>
            )}
            {/* (Top Right) Favorite */}
            {showFavouriteLabel && (
              <div
                className="hover:text-primary absolute right-2 top-2 text-white transition-colors"
                data-testid="product-card-favourite-badge"
              >
                <IconFavourite />
              </div>
            )}
            {/* (Lower Left) Sale Badge */}
            {isOnSale && <SaleLabel data-testid="product-card-sale-badge">Sale</SaleLabel>}
            {showDiscount && !!percentOff && (
              <SaleLabel data-testid="product-card-sale-badge">{roundedPercentOff}% OFF</SaleLabel>
            )}
          </>
        </div>
      )}

      <div
        className={cn('relative flex flex-col px-2 pb-2 transition-colors', contentClassName)}
        data-testid="product-card-content-container"
      >
        <DisplayHeading
          className={cn(
            'text-neutral-strong group-hover:text-link line-clamp-2 leading-normal',
            (!!contentList && contentList.length > 0) || isMinimal ? 'text-sm' : 'text-base',
            'md:font-semibold',
            titleClassName
          )}
          as={titleAs}
          data-testid="product-card-title"
        >
          {title}
        </DisplayHeading>
        {!!normalizedLocations && (
          <div
            className={cn(
              'text-neutral-faded line-clamp-1 text-sm md:w-full',
              isMinimal ? 'w-[calc(100vw - 160px)]' : 'w-full'
            )}
            data-testid="product-card-location-container"
          >
            {normalizedLocations}
          </div>
        )}
        {nearestLocation && (
          <div
            className="text-neutral-faded flex flex-nowrap items-center gap-1 text-sm"
            data-testid="product-card-nearest-location-container"
          >
            <IconLocation />
            <LocationText title={nearestLocation.name}>
              {roundDistance(nearestLocation.distance)}
            </LocationText>
          </div>
        )}
        <div className="flex flex-wrap gap-2 text-sm">
          {hasRating ? (
            <div
              className="flex items-center gap-1"
              data-testid="product-card-reviews-summary-container"
            >
              <RatingsAndReviews averageRating={averageRating} count={totalReviews} />
            </div>
          ) : null}
        </div>

        {contentList && contentList.length > 0 && (
          <ul
            className={cn(
              isMinimal ? 'hidden' : 'text-neutral ml-2 mt-4 list-disc pl-4 text-sm leading-normal'
            )}
            data-testid="product-card-content-list"
          >
            {contentList.map((content, index) =>
              content ? (
                <li
                  key={index}
                  className="my-2 first:mt-0"
                  data-testid="product-card-content-list-item"
                >
                  {content}
                </li>
              ) : null
            )}
          </ul>
        )}

        <div
          className="mt-2 flex flex-nowrap items-baseline gap-1"
          data-testid="product-card-price-container"
        >
          <span
            className={'text-neutral-strong text-base font-semibold leading-none'}
            data-testid="product-card-price"
          >
            {currentPrice}
          </span>
          {pastPrice && (
            <span className="text-neutral-faded text-sm leading-none line-through">
              {pastPrice}
            </span>
          )}

          {pastPrice && !!roundedPercentOff && (
            <span className="text-tones-positive-700 text-xs font-semibold">
              You save {roundedPercentOff}%
            </span>
          )}
        </div>
      </div>
    </LinkWrap>
  );
};

const LocationText = ({ children, ...rest }) => (
  <span className="block truncate" {...rest}>
    {children}
  </span>
);

const SaleLabel = ({ children, ...rest }) => (
  <div
    className="bg-background-primary absolute bottom-0 left-0 rounded px-1 py-0.5 text-xs font-semibold uppercase leading-none text-white"
    {...rest}
  >
    {children}
  </div>
);

const styles = {
  badgeText: 'absolute top-2 left-2 px-1 py-0.5 text-xs leading-[16px] font-semibold rounded',
  badgeTextDefault: 'bg-background-page text-neutral border-border-neutral border',
};
