import { useAspectRatioSupported } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';

import { getCardBadgeText } from '../../utils/getCardBadgeText';
import { mapAspectRatio, mapAspectRatioPolyfill } from '../../utils/mapAspectRatio';

import type { CardImageProps } from './types';

export const ProductCardImage = ({
  cardImage,
  cardTitle,
  isOnSale,
  isNew,
  isExclusive,
  aspectRatio,
  priority,
  hasZoomOnHover,
}: CardImageProps) => {
  const { isSupported } = useAspectRatioSupported();

  const badgeText = getCardBadgeText({ isNew, isExclusive });

  return (
    <div data-testid="product-card-image" className="relative">
      <div
        className={cn(
          'bg-background-neutral relative h-full overflow-hidden',
          isSupported && mapAspectRatio(aspectRatio),
          !isSupported && mapAspectRatioPolyfill(aspectRatio)
        )}
        data-ratio={aspectRatio}
      >
        <Image
          className={cn(
            'image:object-cover duration-400 h-full',
            hasZoomOnHover &&
              'transition-all transition-transform will-change-transform group-hover:scale-105'
          )}
          src={cardImage}
          alt={cardTitle}
          priority={priority}
          fill
        />
        <>
          {/* (Top Left) Exclusive / New badge */}
          {badgeText && (
            <span
              data-testid="product-card-corner-badge"
              className={cn(styles.badgeBase, styles.badgeDefault)}
            >
              {badgeText}
            </span>
          )}

          {/* (Lower Left) Sale Badge */}
          {isOnSale && (
            <span
              data-testid="product-card-sale-badge"
              className="bg-primary-500 absolute bottom-0 left-0 px-1 py-0.5 text-xs font-semibold uppercase leading-none text-white"
            >
              SALE
            </span>
          )}
        </>
      </div>
    </div>
  );
};

export const styles = {
  badgeBase: 'absolute left-2 top-2',
  badgeDefault:
    'bg-background-page text-neutral border-border-neutral self-start rounded border px-1 pb-0.5 pt-[calc(0.125rem_+_1px)] text-xs font-semibold leading-none',
};
