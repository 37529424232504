import { Carousel } from '../../layout/Carousel';
import { CarouselProductCard } from '../../cards/CarouselProduct';
import { ProductCardV2 } from '../../cards/ProductV2';

import type { Product } from '@virginexperiencedays/products';
import type { MouseEvent } from 'react';

export type TopExperiencesProps = {
  href: string;
  products: (Product & {
    onClick?: (e: MouseEvent<HTMLElement>) => void;
  })[];
  hasRecentlyViewed: boolean;
  isProductCardsV2?: boolean;
  className?: string;
};

export const TopExperiences = ({
  className,
  href,
  products,
  hasRecentlyViewed = false,
  isProductCardsV2 = false,
}: TopExperiencesProps) => {
  let productsArray = products;
  if (products?.length > 12) {
    productsArray = products.slice(0, 12);
  }

  if (!products?.length) return null;

  return (
    <div className={className} data-testid="top-experiences">
      <Carousel
        trackClassName="p-px children:max-w-[232px] children:md:max-w-none"
        title="Top Experiences"
        titleSize="2"
        isHrefExternal
        href={href}
        ctaTitle="Show All"
        perPage={
          hasRecentlyViewed
            ? {
                tablet: 2,
                desktop: 3,
                wide: 3,
              }
            : {
                tablet: 3,
                desktop: 4,
                wide: 5,
              }
        }
      >
        {productsArray.map((item) => {
          if (isProductCardsV2) {
            return (
              <ProductCardV2
                {...item}
                percentOff={item?.price?.percentOff}
                displayPrice={item?.price?.displayPrice}
                rrp={item.price.rrp}
                averageRating={item.reviews.averageRating}
                images={[item.media.mainImage, ...(item.media.images || [])].map((image, i) => ({
                  src: image.url,
                  alt: image.altText || `${item.title} ${i + 1}`,
                }))}
              />
            );
          }

          return (
            <CarouselProductCard
              aspectRatio="1/1"
              key={item.id}
              averageRating={item.reviews?.averageRating}
              displayPrice={item.price?.displayPrice}
              isCollectionProduct={item.isCollectionProduct}
              productPromo={item.productPromo}
              isExclusive={item.isExclusive}
              isNew={item?.isNew}
              isOnSale={!!item.price?.rrp}
              locations={item.locations}
              percentOff={item.price?.percentOff ?? null}
              promocode={item.promocode}
              rrp={item.price?.displayPrice !== item.price?.rrp ? item.price?.rrp : null}
              sku={item.sku}
              slug={item.slug}
              src={item.media?.mainImage?.url}
              title={item.title}
              totalReviews={item.reviews?.count}
            />
          );
        })}
      </Carousel>
    </div>
  );
};
